import $ from './jquery-3.6.1.min.js'
//
// user.js
// Use this to write your custom JS
//

//Get the back to top button:
mybutton = document.getElementById("backTop");

// When the user scrolls down 200px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}

// Method to send email to TCS Controls
window.sendMail = function(document){
  // Get the inputs and place into variables
      var sender = document.getElementById("contactemail").value;
      var name = document.getElementById("fullname").value;
      var company = document.getElementById("contactcompany").value;
      var msg = document.getElementById("contactmessage").value;
      var phone = document.getElementById("contactphone").value;
      var subject = "Enquiry by " + name;
      var body = company + "<br>" + msg;


      $.ajax({
  	    type: "POST",
  	    url: 'https://contactform.javin.com.my/sendemail/send.php',
  	    dataType: "json",
  	    data: {'sender': sender, 'name': name, 'company': company, 'msg': msg, 'phone': phone, 'subject': subject, 'body': body},
  	    success: function(result) {
  			if( result.status == 1){
  				console.log("Sending...");
  			}else{
  				console.log("Error 1");
  			}
  	    },
  	    error: function() {
  			console.log("Error 2");
  	    },
  	});

  	setTimeout(function(){
  		alert("Your message successfully sent.");
  	}, 200)

  }
